<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <div class="bg">
      <div class="auth-wrapper auth-v1 px-2">
        <div class="auth-inner py-2">
          <!-- Login v1 -->
          <b-card class="mb-0">

            <!-- form -->
            <!-- form -->
            <validation-observer
              ref="loginForm"
              #default="{invalid}"
            >
              <b-form
                class="auth-login-form"
                @submit.prevent="login"
              >

                <!-- email -->
                <b-form-group>
                  <label for="Username">ชื่อผู้ใช้งาน</label>
                  <validation-provider
                    #default="{ errors }"
                    name="Username"
                    vid="Username"
                    rules="required"
                  >
                    <b-form-input
                      id="Username"
                      v-model="userEmail"
                      :state="errors.length > 0 ? false : null"
                      name="Username"
                    />
                    <small class="text-danger">{{ errors[0] ? 'กรอกชื่อผู้ใช้งานให้ถูกต้อง' : '' }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- forgot password -->
                <b-form-group>
                  <label for="login-password">รหัสผ่าน</label>
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    vid="password"
                    rules="required"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      @keydown.enter.prevent="GetOTP"
                    />
                    <small class="text-danger">{{ errors[0] ? 'กรอกรหัสผ่านให้ถูกต้อง' : '' }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- checkbox -->
                <b-form-group>
                  <b-form-checkbox
                    id="remember-me"
                    v-model="status"
                    name="checkbox-1"
                  >
                    <span class="text-white">จดจำฉันไว้ในระบบ</span>
                  </b-form-checkbox>
                </b-form-group>

                <!-- submit buttons -->
                <b-button
                  v-if="nextstep === 0"
                  variant="primary"
                  block
                  :disabled="invalid"
                  @click="GetOTP()"
                >
                  ขอ OTP
                </b-button>
                <b-form-group
                  v-if="nextstep === 1"
                  label="OTP"
                  label-for="OTP"
                >
                  <p style="color: red">
                    ref : {{ ref }}
                  </p>
                  <validation-provider
                    #default="{ errors }"
                    name="OTP"
                    vid="OTP"
                    rules="required"
                  >
                    <b-form-input
                      id="OTP"
                      v-model="OTP"
                      :state="errors.length > 0 ? false : null"
                      name="OTP"
                      placeholder="กรุณากรอก OTP"
                      @keydown.enter.prevent="CheckOTP"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-button
                  v-if="nextstep === 1"
                  variant="primary"
                  block
                  :disabled="invalid"
                  @click="CheckOTP()"
                >
                  เข้าสู่ระบบ
                </b-button>
              </b-form>
            </validation-observer>

          <!-- <div class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div> -->
          </b-card>
        <!-- /Login v1 -->
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  // BImg,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BCardText,
  BCard,
  BForm,
  BButton,
  BOverlay,
  VBTooltip,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import BUID from 'uniquebrowserid'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    // BImg,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    BCard,

    BOverlay,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      ref: null,
      show: false,
      OTP: '',
      nextstep: 0,
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),

      // validation rules
      required,
      email,
      ag: 1,
      urlori: window.location.origin,
      AgLog: {
        ip: null,
        latitude: '',
        longitude: '',
        buid: new BUID().completeID(),
      },
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  mounted() {
    // this.url = this.$route.query.url
    // this.getagid()
    this.GetLocation()
    this.GetIp()
    this.ag = 1
  },
  methods: {
    GetLocation() {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(position => {
          const { latitude } = position.coords
          const { longitude } = position.coords
          this.AgLog.latitude = latitude
          this.AgLog.longitude = longitude
        })
      }
    },
    async GetIp() {
      await axios
        .get('https://api.ipify.org')
        .then(response => {
          this.AgLog.ip = response.data
        })
        .catch(error => console.log(error))
    },
    getagid() {
      const obj = {
        site_admin: window.location.origin,
      }
      this.$http
        .post('https://adminapi.power100th.com/api/agent/showid', obj)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.ag = response.data.id
          localStorage.setItem('endpoint', response.data.endpoint)
          this.show = false
        })
        .catch(error => console.log(error))
    },
    GetOTP() {
      this.show = true
      const obj = {
        name: this.userEmail,
        agent_id: this.ag,
        ip: this.AgLog.ip,
      }
      this.$http
        .post('/otplogin/store', obj)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          // console.log(response.data.ref)
          this.ref = response.data
          this.nextstep = 1
          this.show = false
        })
        .catch(error => console.log(error))
    },
    CheckOTP() {
      const obj = {
        name: this.userEmail,
        otps: this.OTP,
        agent_id: this.ag,
      }
      this.$http
        .post('/otplogin/check', obj)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.login()
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'OTP ไม่ถูกต้อง',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: error,
            },
          })
        })
    },
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          useJwt
            .login({
              email: this.userEmail,
              password: this.password,
              agent_id: this.ag,
            })
            .then(response => {
              const userData = response.data
              useJwt.setToken(response.data.token)
              useJwt.setRefreshToken(response.data.refreshToken)
              localStorage.setItem('userData', JSON.stringify(userData))
              this.$ability.update(userData.ability)
              if (userData.role === 'zean') {
                this.LogAdminZean()
              } else {
                this.LogAdmin()
              }
              // ? This is just for demo purpose as well.
              // ? Because we are showing eCommerce app's cart items count in navbar
              // this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

              // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
              this.$router
                .push(getHomeRouteForLoggedInUser(userData.role))
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Welcome to Image Dashboard',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: 'You have successfully logged ',
                    },
                  })
                })
                .catch(error => {
                  this.$refs.loginForm.setErrors(error.response.data.error)
                })
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'ERROR Email or Password incorrect',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: error,
                },
              })
            })
        }
      })
    },
    LogAdmin() {
      const params = {
        on_page: 'login',
        ip: this.AgLog.ip,
        latitude: this.AgLog.latitude,
        longitude: this.AgLog.longitude,
        buid: this.AgLog.buid,
      }
      this.$http.get('/admin/log', { params })
    },
    LogAdminZean() {
      const params = {
        on_page: 'login',
        ip: this.AgLog.ip,
        latitude: this.AgLog.latitude,
        longitude: this.AgLog.longitude,
      }
      this.$http.get('/admin/log/zean', { params })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>

<style scoped>
  .bg {
    background-image: url('/bg-2.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .card {
    border-radius: 14px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    background-color: rgba(56, 56, 56, 0.452);
  }
  label {
    font-size: 14px;
    font-weight: 500;
    color: #fff;
  }
  </style>
